<template>
    <v-dialog
        :value="value"
        scrollable
        width="700"
        @input="onInput(false)"
        @keydown.esc="onInput(false)"
        @click:outside="onInput(false)"
    >
        <questions-list
            :key="`listcomponent${listComponentKey}`"
            :title="title"
            height="700"
            :payload="payload"
            hide-author
            hide-size
            searchable
            disabled-items
            @loaded="onLoaded"
        >
            <template #footer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="onCancel"
                >
                    {{ $trans('Cancel') }}
                </v-btn>
                <v-btn
                    :disabled="disabledAction"
                    color="primary"
                    text
                    @click="onSelect"
                >
                    {{ actionText }}
                </v-btn>
            </template>
        </questions-list>
    </v-dialog>
</template>

<script>
export default {
    name: 'QuestionsExplorer',
    components: {
        QuestionsList: () => import('@apps/questions/components/QuestionsList')
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        },
        actionText: {
            type: String,
            default: 'OK'
        },
        disabledAction: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        canMove() {
            return this.parent &&
                this.selectedFolder
        }
    },
    watch: {
        value(flag) {
            if(flag) {
                this.listComponentKey++
            }
        }
    },
    data() {
        return {
            listComponentKey: 0,
            mediaTableKey: 0,
            selectedFolder: null
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },
        onCancel() {
            this.selectedFolder = null
            this.$emit('input', false)
        },
        onLoaded(folder) {
            this.selectedFolder = folder
            this.$emit('loaded', folder)
        },
        onSelect() {
            this.$emit('select', this.selectedFolder)
            this.selectedFolder = null
            this.$emit('input', false)
        }
    }
}
</script>

<style lang=scss>

</style>
